<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        患者姓名：
        <el-select v-model="queryObj.patientId" placeholder="全部" clearable filterable>
          <el-option :value="item.id" v-for="(item, index) in patientList" :key="index" :label="item.name"></el-option>
        </el-select>
      </div>
      <div>
        查询时间：
        <el-date-picker v-model="queryTime" type="daterange" align="right" size="small" time-arrow-control key="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="display: inline-flex; width: 210px; height: 34px" value-format="yyyy-MM-dd"> </el-date-picker>
      </div>
      <span>
        <el-button v-if="$has('viewPatientDoserate')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button v-if="$has('viewPatientDoserate')" type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('exportPatientDoserateHistory')" type="primary" size="mini" icon="el-icon-upload2" @click="exportes">导出Excel</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称" width="101"></el-table-column>
        <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="patientName" label="姓名"> </el-table-column>
        <el-table-column prop="doserate" label="剂量监测结果(μSv/h)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phaseMbq" label="剂量监测结果(Mbq)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phaseMci" label="剂量监测结果(MCi)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="treatmentNum" label="入院次数 / 测量次数">
          <template slot-scope="{ row }">
            {{ row.hospitalizationNum }} / <i style="font-weight: bold">{{ row.treatmentNum }}</i>
          </template>
        </el-table-column>
        <el-table-column prop="createDt" label="创建时间" width="160"></el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
    </div>
  </div>
</template>
<script>
import { radPatientDoserateQuery, radPatientDoserateDownloadExcel, radPatientQuery } from '@/util/http'
import ToTop from '@/components/toTop/toTop.vue'
import inputList from '@/components/inputList/inputList.vue'
export default {
  components: { ToTop, inputList },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [],
      queryObj: {
        areaId: '',
        organizationId: '',
        patientId: '',
      },
      queryTime: ['', ''],
      patientList: [],
    }
  },
  watch: {
    // 监听日期类型
    queryTime(newVal) {
      if (newVal == null) {
        this.queryTime = ['', '']
      }
    },
  },
  methods: {
    // 拿值区域和机构
    getData(msg) {
      this.queryObj.areaId = msg[0]
      this.queryObj.organizationId = msg[1]
      if (msg[1]) {
        this.getPatientList()
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        ...this.queryObj,
        queryBeginDt: this.queryTime[0],
        queryEndDt: this.queryTime[1],
      }
      await radPatientDoserateQuery(params)
        .then((data) => {
          if (data) {
            this.tableData = data.result.records
            this.totals = data.result.total
          }
        })
        .catch(() => {})
      loading.close()
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.queryObj = {
        areaId: '',
        organizationId: '',
        patientId: '',
      }
      this.queryTime = ['', '']
      this.patientList = []
      this.searchs(1, 10)
    },
    // 导出Excel
    exportes() {
      let params = {
        ...this.queryObj,
        queryBeginDt: this.queryTime[0],
        queryEndDt: this.queryTime[1],
        pageNo: 1,
        pageSize: 1000000,
      }
      radPatientDoserateDownloadExcel(params).then((res) => {
        if (res) {
          const blob = new Blob([res])
          var a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = '患者剂量管理.xlsx'
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
      })
    },
    // 获取患者列表
    getPatientList() {
      let params = {
        areaId: this.queryObj.areaId,
        organizationId: this.queryObj.organizationId,
        pageNo: 1,
        pageSize: 1000000,
      }
      radPatientQuery(params).then((res) => {
        if (res) {
          this.patientList = res.result.records
        }
      })
    },
  },
  mounted() {
    this.searchs(1, 10)
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
.item {
  margin-top: 10px;
  /deep/.el-badge__content.is-fixed {
    right: 0;
  }
}
</style>
